import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../config/firebase';
import { auth } from '../config/firebase';
import { MerchantCustomization } from '../services/merchant/config';
import { generateInitialConfig } from '../services/config/defaults';
import { createSubdomain } from '../services/subdomain/manager';

// Remove direct import of authStore
let currentUser: any = null;

// Subscribe to auth changes
auth.onAuthStateChanged((user) => {
  currentUser = user;
});

interface OnboardingState {
  currentStep: number;
  stepsCompleted: Record<string, boolean>;
  isComplete: boolean;
  config: MerchantCustomization;
  isDeployed: boolean;
  isDeploying: boolean;
  chatUrl: string | null;
  error: string | null;
  setStep: (step: number) => void;
  completeStep: (step: string) => void;
  completeOnboarding: () => void;
  updateConfig: (updates: Partial<MerchantCustomization>) => void;
  setConfig: (config: MerchantCustomization) => void;
  deploy: () => Promise<void>;
  updateLiveChat: () => Promise<void>;
  resetOnboarding: () => void;
  initializeStore: (storeName: string) => Promise<void>;
  setError: (error: string | null) => void;
}

async function saveSettings(userId: string, config: Partial<MerchantCustomization>, storeName: string) {
  const configRef = doc(db, `merchants/${userId}/merchantConfig/settings`);
  await setDoc(configRef, {
    ...config,
    updatedAt: serverTimestamp()
  }, { merge: true });
}

export const useOnboardingStore = create<OnboardingState>()(
  persist(
    (set, get) => {
      const initialState = {
        currentStep: 0,
        stepsCompleted: {},
        isComplete: false,
        config: generateInitialConfig(''),
        isDeployed: false,
        isDeploying: false,
        chatUrl: null,
        error: null
      };

      return {
        ...initialState,
        setStep: (step) => set({ currentStep: step }),
        completeStep: (step) => set((state) => ({ 
          stepsCompleted: { ...state.stepsCompleted, [step]: true } 
        })),
        completeOnboarding: () => set({ isComplete: true }),
        updateConfig: (updates) => set((state) => ({ 
          config: { ...state.config, ...updates } 
        })),
        setConfig: (config) => set({ config }),
        deploy: async () => {
          const state = get();
          if (!currentUser) {
            console.error('Deploy failed: No user found');
            set({ error: 'You must be logged in to deploy', isDeploying: false });
            throw new Error('You must be logged in to deploy');
          }

          try {
            set({ isDeploying: true, error: null });
            
            // Save settings using settingsService
            await saveSettings(currentUser.uid, state.config, state.config.storeInfo.name);

            // Update merchant document
            const merchantRef = doc(db, `merchants/${currentUser.uid}`);
            await setDoc(merchantRef, {
              name: state.config.storeInfo.name,
              chatUrl: state.chatUrl,
              updatedAt: serverTimestamp()
            }, { merge: true });

            set({ isDeployed: true, isDeploying: false });
          } catch (error) {
            console.error('Deploy failed:', error);
            set({ 
              error: error instanceof Error ? error.message : 'Deployment failed',
              isDeploying: false 
            });
            throw error;
          }
        },
        updateLiveChat: async () => {
          if (!currentUser) {
            throw new Error('You must be logged in to update chat');
          }

          const state = get();
          await saveSettings(currentUser.uid, {
            chat: state.config.chat
          }, state.config.storeInfo.name);
        },
        resetOnboarding: () => set(initialState),
        initializeStore: async (storeName: string) => {
          if (!currentUser) {
            throw new Error('You must be logged in to initialize a store');
          }

          try {
            // Create subdomain first
            const subdomainResult = await createSubdomain(currentUser.uid, storeName);
            if (!subdomainResult.success) {
              throw new Error(subdomainResult.error || 'Failed to create subdomain');
            }

            // Update config with store name
            const updatedConfig = {
              ...generateInitialConfig(''),
              storeInfo: {
                ...generateInitialConfig('').storeInfo,
                name: storeName
              }
            };
            
            // Save settings using settingsService
            await saveSettings(currentUser.uid, updatedConfig, storeName);

            // Update merchant document
            const merchantRef = doc(db, `merchants/${currentUser.uid}`);
            await setDoc(merchantRef, {
              name: storeName,
              chatUrl: subdomainResult.chatUrl,
              updatedAt: serverTimestamp()
            }, { merge: true });

            // Update local state
            set({
              config: updatedConfig,
              chatUrl: subdomainResult.chatUrl,
              error: null,
              stepsCompleted: {
                ...initialState.stepsCompleted,
                storeBasics: true
              }
            });

          } catch (error) {
            console.error('Store initialization failed:', error);
            set({ 
              error: error instanceof Error ? error.message : 'Failed to initialize store'
            });
            throw error;
          }
        },
        setError: (error: string | null) => set({ error })
      };
    },
    {
      name: 'onboarding-store'
    }
  )
); 
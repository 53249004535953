import React, { useState } from 'react';
import { Image } from 'lucide-react';
import { useOnboardingStore } from '../../../stores/onboardingStore';
import ChatPreview from '../ChatPreview';
import FormField from '../../ui/FormField';
import Button from '../../ui/Button';

export default function BrandingStep() {
  const { config, updateConfig } = useOnboardingStore();
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const handleColorChange = (color: string) => {
    updateConfig({
      branding: {
        ...config.branding,
        colors: {
          ...config.branding.colors,
          primary: color
        }
      }
    });
  };

  const handleLogoChange = (file: File | null) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        updateConfig({
          branding: {
            ...config.branding,
            logo: reader.result as string
          }
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFaviconUpload = async (file: File) => {
    // TODO: Implement favicon upload
    const faviconUrl = URL.createObjectURL(file);
    updateConfig('branding', {
      chatIcon: faviconUrl
    });
  };

  return (
    <div className="space-y-6">
      <FormField label="Brand Color">
        <div className="flex items-center space-x-4">
          <input
            type="color"
            value={config.branding.colors.primary}
            onChange={(e) => handleColorChange(e.target.value)}
            className="w-12 h-12 rounded-lg cursor-pointer"
          />
          <input
            type="text"
            value={config.branding.colors.primary}
            onChange={(e) => handleColorChange(e.target.value)}
            className="flex-1 px-4 py-2 border rounded-lg border-gray-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            placeholder="#000000"
          />
        </div>
      </FormField>

      <FormField label="Logo">
        <div className="flex items-center space-x-4">
          {config.branding.logo ? (
            <img
              src={config.branding.logo}
              alt="Logo"
              className="w-12 h-12 rounded-lg object-cover"
            />
          ) : (
            <div className="w-12 h-12 rounded-lg bg-gray-100 flex items-center justify-center">
              <Image className="w-6 h-6 text-gray-400" />
            </div>
          )}
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleLogoChange(e.target.files?.[0] || null)}
            className="flex-1"
          />
        </div>
      </FormField>

      <Button
        variant="secondary"
        onClick={() => setIsPreviewOpen(true)}
        className="w-full"
      >
        Preview Chat
      </Button>

      <ChatPreview
        isOpen={isPreviewOpen}
        onClose={() => setIsPreviewOpen(false)}
        config={config}
      />
    </div>
  );
} 
import React from 'react';
import { useOnboardingStore } from '../../../stores/onboardingStore';
import FormField from '../../ui/FormField';
import { Phone, Mail } from 'lucide-react';

export default function LeadCaptureStep() {
  const { config, updateConfig } = useOnboardingStore();
  const { leads } = config;

  const handleChange = (field: keyof typeof leads, value: string) => {
    updateConfig({
      leads: {
        ...config.leads,
        [field]: value
      }
    });
  };

  return (
    <div className="space-y-6">
      <div className="bg-gradient-to-br from-blue-50 to-indigo-50 rounded-lg p-6 mb-8">
        <h3 className="text-lg font-semibold text-blue-900 mb-2">
          Lead Capture Setup
        </h3>
        <p className="text-sm text-blue-700">
          Configure how you want to receive leads from your AI assistant. You can receive
          notifications via SMS, email, or both.
        </p>
      </div>

      <FormField label="Email Notifications">
        <div className="flex items-center space-x-4">
          <div className="flex-shrink-0">
            <Mail className="w-5 h-5 text-gray-400" />
          </div>
          <input
            type="email"
            className="flex-1 px-4 py-2 border rounded-lg border-gray-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            placeholder="admin@yourstore.com"
            value={leads.adminEmail}
            onChange={(e) => handleChange('adminEmail', e.target.value)}
          />
        </div>
        <p className="mt-1 text-sm text-gray-500">
          Email address where you want to receive lead notifications
        </p>
      </FormField>

      <FormField label="SMS Notifications">
        <div className="flex items-center space-x-4">
          <div className="flex-shrink-0">
            <Phone className="w-5 h-5 text-gray-400" />
          </div>
          <input
            type="tel"
            className="flex-1 px-4 py-2 border rounded-lg border-gray-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            placeholder="(555) 123-4567"
            value={leads.adminMobile}
            onChange={(e) => handleChange('adminMobile', e.target.value)}
          />
        </div>
        <p className="mt-1 text-sm text-gray-500">
          Phone number where you want to receive SMS notifications
        </p>
      </FormField>

      <FormField label="Notification Preference">
        <select
          className="w-full px-4 py-2 border rounded-lg border-gray-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          value={leads.notificationPreference}
          onChange={(e) => handleChange('notificationPreference', e.target.value)}
        >
          <option value="email">Email Only</option>
          <option value="sms">SMS Only</option>
          <option value="both">Both Email and SMS</option>
        </select>
        <p className="mt-1 text-sm text-gray-500">
          Choose how you want to receive notifications
        </p>
      </FormField>
    </div>
  );
} 
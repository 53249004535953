import React, { useState } from 'react';
import { Moon, User, Menu, LogOut, Settings } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../ui/Button';
import Breadcrumbs from './Breadcrumbs';
import { useSidebarStore } from '../../stores/sidebarStore';
import { useAuthStore } from '../../stores/authStore';

interface HeaderProps {
  isSuperAdmin?: boolean;
}

const Header: React.FC<HeaderProps> = ({ isSuperAdmin }) => {
  const setMobileOpen = useSidebarStore((state) => state.setMobileOpen);
  const { logout, user } = useAuthStore();
  const navigate = useNavigate();
  const [showUserMenu, setShowUserMenu] = useState(false);
  const currentPath = window.location.pathname;
  
  const getBreadcrumbItems = () => {
    const paths = currentPath.split('/').filter(Boolean);
    return paths.map((path) => ({
      label: path.charAt(0).toUpperCase() + path.slice(1),
      href: `/${paths.slice(0, paths.indexOf(path) + 1).join('/')}`,
    }));
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <header className="h-16 fixed right-0 top-0 left-0 lg:left-64 bg-white border-b border-gray-200 z-10 backdrop-blur-sm bg-white/90">
      <div className="h-full px-4 lg:px-8 flex items-center justify-between">
        <div className="flex items-center gap-4">
          <button 
            onClick={() => setMobileOpen(true)}
            className="p-2 hover:bg-gray-100 rounded-lg transition-colors lg:hidden"
            aria-label="Open menu"
          >
            <Menu className="h-5 w-5" />
          </button>
          <div className="hidden lg:block">
            <Breadcrumbs items={getBreadcrumbItems()} />
          </div>
        </div>
        
        <div className="flex items-center gap-2 lg:gap-4">
          {isSuperAdmin && (
            <Link
              to="/admin/brands"
              className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
            >
              Admin Portal
            </Link>
          )}
          <Button variant="primary" className="hidden lg:flex">
            Premium Plan
          </Button>
          <div className="hidden sm:flex items-center gap-2 text-green-500 bg-green-50 px-3 py-1.5 rounded-full">
            <span className="h-2 w-2 bg-green-500 rounded-full animate-pulse"></span>
            <span className="text-sm font-medium">Online</span>
          </div>
          <button className="p-2 hover:bg-gray-100 rounded-full transition-colors">
            <Moon className="h-5 w-5" />
          </button>
          <div className="relative">
            <button 
              className="p-2 hover:bg-gray-100 rounded-full transition-colors"
              onClick={() => setShowUserMenu(!showUserMenu)}
            >
              <User className="h-5 w-5" />
            </button>
            
            {showUserMenu && (
              <div className="absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-lg py-1 border border-gray-200">
                <div className="px-4 py-2 border-b border-gray-200">
                  <p className="text-sm font-medium text-gray-900">Account</p>
                  <p className="text-sm text-gray-500 truncate">{user?.email}</p>
                </div>
                <Link
                  to="/settings"
                  className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 flex items-center gap-2"
                  onClick={() => setShowUserMenu(false)}
                >
                  <Settings className="h-4 w-4" />
                  <span>Account Settings</span>
                </Link>
                <button
                  onClick={handleLogout}
                  className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 flex items-center gap-2"
                >
                  <LogOut className="h-4 w-4" />
                  <span>Log Out</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
import React, { useState } from 'react';
import { useOnboardingStore } from '../../../stores/onboardingStore';
import FormField from '../../ui/FormField';
import { toast } from 'react-hot-toast';

export default function StoreBasicsStep() {
  const [storeName, setStoreName] = useState('');
  const [isInitializing, setIsInitializing] = useState(false);
  const { config, initializeStore, updateConfig, chatUrl } = useOnboardingStore();

  const handleStoreNameChange = async (value: string) => {
    setStoreName(value);
  };

  const handleInitialize = async () => {
    if (!storeName.trim()) {
      toast.error('Please enter a store name');
      return;
    }

    setIsInitializing(true);
    try {
      await initializeStore(storeName.trim());
      toast.success('Store initialized successfully!');
    } catch (error) {
      console.error('Failed to initialize store:', error);
      toast.error(error instanceof Error ? error.message : 'Failed to initialize store');
    } finally {
      setIsInitializing(false);
    }
  };

  const handleStoreInfoChange = (field: keyof typeof config.storeInfo, value: string) => {
    updateConfig({
      storeInfo: {
        ...config.storeInfo,
        [field]: value
      }
    });
  };

  // If store is already initialized, show the current configuration
  if (config?.storeInfo.name) {
    return (
      <div className="space-y-6">
        <div className="bg-blue-50 border border-blue-200 rounded-lg p-4">
          <h3 className="text-blue-800 font-medium mb-2">Store Initialized!</h3>
          <p className="text-blue-600 text-sm mb-2">
            Your store has been created and your chat is ready at:
          </p>
          <a
            href={`https://${chatUrl}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:text-blue-700 font-mono text-sm break-all"
          >
            {chatUrl}
          </a>
        </div>

        <FormField label="Store Name">
          <input
            type="text"
            className="w-full px-4 py-2 border rounded-lg border-gray-200 bg-gray-50"
            value={config.storeInfo.name}
            disabled
          />
          <p className="mt-1 text-sm text-gray-500">
            Store name cannot be changed after initialization
          </p>
        </FormField>

        <FormField label="Store Hours">
          <textarea
            className="w-full px-4 py-2 border rounded-lg border-gray-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 h-24 resize-none"
            placeholder="Mon-Fri: 9am-6pm&#10;Sat: 10am-5pm&#10;Sun: Closed"
            value={config.storeInfo.hours}
            onChange={(e) => handleStoreInfoChange('hours', e.target.value)}
          />
          <p className="mt-1 text-sm text-gray-500">
            Your store hours will help the AI provide accurate availability information
          </p>
        </FormField>

        <FormField label="Location">
          <input
            type="text"
            className="w-full px-4 py-2 border rounded-lg border-gray-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            placeholder="123 Main St, City, State"
            value={config.storeInfo.location}
            onChange={(e) => handleStoreInfoChange('location', e.target.value)}
          />
          <p className="mt-1 text-sm text-gray-500">
            Your store's physical location
          </p>
        </FormField>

        <FormField label="Phone">
          <input
            type="tel"
            className="w-full px-4 py-2 border rounded-lg border-gray-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            placeholder="(555) 123-4567"
            value={config.storeInfo.phone}
            onChange={(e) => handleStoreInfoChange('phone', e.target.value)}
          />
          <p className="mt-1 text-sm text-gray-500">
            Your store's contact number
          </p>
        </FormField>

        <FormField label="Email">
          <input
            type="email"
            className="w-full px-4 py-2 border rounded-lg border-gray-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            placeholder="contact@yourstore.com"
            value={config.storeInfo.email}
            onChange={(e) => handleStoreInfoChange('email', e.target.value)}
          />
          <p className="mt-1 text-sm text-gray-500">
            Your store's contact email
          </p>
        </FormField>
      </div>
    );
  }

  // Initial store setup form
  return (
    <div className="space-y-6">
      <div className="bg-blue-50 border border-blue-200 rounded-lg p-4">
        <h3 className="text-blue-800 font-medium mb-2">Let's Get Started!</h3>
        <p className="text-blue-600 text-sm">
          Enter your store name to create your AI assistant. This will automatically create your chat URL and initialize your store with default settings.
        </p>
      </div>

      <FormField label="Store Name">
        <input
          type="text"
          className="w-full px-4 py-2 border rounded-lg border-gray-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          placeholder="Enter your store name"
          value={storeName}
          onChange={(e) => handleStoreNameChange(e.target.value)}
          disabled={isInitializing}
        />
        <p className="mt-1 text-sm text-gray-500">
          This will be used to create your chat URL and cannot be changed later
        </p>
      </FormField>

      <button
        className={`w-full py-2 px-4 rounded-lg text-white font-medium ${
          isInitializing
            ? 'bg-blue-400 cursor-not-allowed'
            : 'bg-blue-500 hover:bg-blue-600'
        }`}
        onClick={handleInitialize}
        disabled={isInitializing || !storeName.trim()}
      >
        {isInitializing ? 'Initializing...' : 'Initialize Store'}
      </button>
    </div>
  );
} 
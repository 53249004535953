import React, { useState, useEffect, useCallback } from 'react';
import { collection, doc, setDoc, deleteDoc, getDocs, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { Brand, Series, ModelDetails } from '../brands/types';
import Button from '../ui/Button';
import Card from '../ui/Card';
import FormField from '../ui/FormField';

interface BrandFormData {
  id: string;
  name: string;
  series: {
    [key: string]: {
      name: string;
      models: {
        [key: string]: ModelDetails;
      };
    };
  };
}

const emptyBrandForm: BrandFormData = {
  id: '',
  name: '',
  series: {
    "0": {
      name: '',
      models: {
        "0": {
          id: '',
          name: '',
          description: '',
          specs: {},
          priceRange: { min: 0, max: 0 },
          selected: false,
          productUrl: ''
        }
      }
    }
  }
};

const sampleJson = [
  {
    "name": "Sample Brand",
    "series": [
      {
        "name": "Luxury Collection",
        "models": [
          {
            "name": "Cloud Supreme",
            "description": "Premium memory foam mattress with cooling technology. Ideal for side sleepers who prefer a balanced level of support and softness. Perfect for individuals seeking pressure relief and a cool sleeping surface.",
            "specs": {
              "firmness": "Medium",
              "height": "12 inches",
              "material": "Memory Foam",
              "warranty": "10 years",
              "cooling": "Gel-infused memory foam"
            },
            "priceRange": {
              "min": 999,
              "max": 1999
            },
            "productUrl": "https://example.com/cloud-supreme"
          }
        ]
      }
    ]
  }
];

const AdminBrandsPage = () => {
  const [brands, setBrands] = useState<Brand[]>([]);
  const [editingBrand, setEditingBrand] = useState<BrandFormData | null>(null);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [expandedBrands, setExpandedBrands] = useState<Set<string>>(new Set());
  const [isDragging, setIsDragging] = useState(false);
  const [importProgress, setImportProgress] = useState<{
    total: number;
    current: number;
    status: string;
  } | null>(null);
  const [showJsonExample, setShowJsonExample] = useState(false);
  const [jsonInput, setJsonInput] = useState('');

  const fetchBrands = async () => {
    try {
      console.log('Starting fetchBrands...');
      setLoading(true);
      const brandsRef = collection(db, 'brands');
      console.log('Getting documents from collection...');
      const snapshot = await getDocs(brandsRef);
      console.log('Got snapshot:', snapshot.size, 'documents');
      
      const fetchedBrands: Brand[] = [];

      // Process each document
      snapshot.forEach(doc => {
        console.log('Document ID:', doc.id);
        console.log('Document data:', doc.data());

        // Skip non-brand documents
        if (doc.id === 'systemBrands' || doc.id === 'merchantInventory' || doc.id === 'users') {
          console.log('Skipping system document:', doc.id);
          return;
        }

        const data = doc.data();
        
        // Process series data
        let seriesArray: Series[] = [];
        if (data.series && typeof data.series === 'object') {
          console.log('Processing series for brand:', doc.id);
          console.log('Raw series data:', data.series);
          
          // Convert numbered series objects to array
          Object.entries(data.series).forEach(([seriesKey, seriesData]: [string, any]) => {
            console.log('Processing series:', seriesKey, seriesData);
            const modelsArray: ModelDetails[] = [];
            
            // Convert numbered models objects to array
            if (seriesData.models && typeof seriesData.models === 'object') {
              console.log('Processing models for series:', seriesKey);
              Object.entries(seriesData.models).forEach(([modelKey, modelData]: [string, any]) => {
                console.log('Processing model:', modelKey, modelData);
                modelsArray.push({
                  id: modelData.id || `${doc.id}-${seriesKey}-${modelKey}`,
                  name: modelData.name || '',
                  description: modelData.description || '',
                  specs: modelData.specs || {},
                  priceRange: {
                    min: modelData.priceRange?.min || 0,
                    max: modelData.priceRange?.max || 0
                  },
                  selected: modelData.selected || false,
                  productUrl: modelData.productUrl || ''
                });
              });
            }

            seriesArray.push({
              name: seriesData.name || `Series ${parseInt(seriesKey) + 1}`,
              models: modelsArray
            });
          });
        }

        const brand: Brand = {
          id: doc.id,
          name: data.name || '',
          createdAt: data.createdAt || new Date().toISOString(),
          updatedAt: data.updatedAt || new Date().toISOString(),
          isCustom: data.isCustom ?? false,
          series: seriesArray
        };

        console.log('Processed brand:', brand);
        fetchedBrands.push(brand);
      });

      console.log('All fetched brands:', fetchedBrands);
      setBrands(fetchedBrands.sort((a, b) => a.name.localeCompare(b.name)));
    } catch (error) {
      console.error('Error fetching brands:', error);
      setError('Failed to load brands');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log('AdminBrandsPage mounted');
    fetchBrands();
  }, []);

  const handleAddBrand = () => {
    setEditingBrand({ ...emptyBrandForm });
    setShowForm(true);
  };

  const handleEditBrand = (brand: Brand) => {
    // Convert brand data to form format
    const formData: BrandFormData = {
      id: brand.id,
      name: brand.name,
      series: brand.series.reduce((acc, series, index) => {
        acc[index.toString()] = {
          name: series.name,
          models: series.models.reduce((modelAcc, model, modelIndex) => {
            modelAcc[modelIndex.toString()] = model;
            return modelAcc;
          }, {} as { [key: string]: ModelDetails })
        };
        return acc;
      }, {} as BrandFormData['series'])
    };
    setEditingBrand(formData);
    setShowForm(true);
  };

  const handleDeleteBrand = async (brandId: string) => {
    if (!confirm('Are you sure you want to delete this brand? This action cannot be undone.')) return;
    
    try {
      setLoading(true);
      const brandRef = doc(db, 'brands', brandId);
      await deleteDoc(brandRef);
      await fetchBrands(); // Refresh the list after deletion
      setError(null);
    } catch (error) {
      setError('Failed to delete brand');
      console.error('Error deleting brand:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddSeries = () => {
    if (!editingBrand) return;
    const newSeriesKey = Object.keys(editingBrand.series).length.toString();
    setEditingBrand({
      ...editingBrand,
      series: {
        ...editingBrand.series,
        [newSeriesKey]: {
          name: '',
          models: {
            "0": {
              id: `${editingBrand.id}-${newSeriesKey}-0`,
              name: '',
              description: '',
              specs: {},
              priceRange: { min: 0, max: 0 },
              selected: false,
              productUrl: ''
            }
          }
        }
      }
    });
  };

  const handleAddModel = (seriesKey: string) => {
    if (!editingBrand) return;
    const series = editingBrand.series[seriesKey];
    const newModelKey = Object.keys(series.models).length.toString();
    setEditingBrand({
      ...editingBrand,
      series: {
        ...editingBrand.series,
        [seriesKey]: {
          ...series,
          models: {
            ...series.models,
            [newModelKey]: {
              id: `${editingBrand.id}-${seriesKey}-${newModelKey}`,
              name: '',
              description: '',
              specs: {},
              priceRange: { min: 0, max: 0 },
              selected: false,
              productUrl: ''
            }
          }
        }
      }
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!editingBrand) return;

    try {
      setLoading(true);
      setError(null);
      
      // Validate price ranges
      for (const series of Object.values(editingBrand.series)) {
        for (const model of Object.values(series.models)) {
          if (!model.priceRange || model.priceRange.min === undefined || model.priceRange.max === undefined) {
            throw new Error('All models must have valid price ranges');
          }
          if (model.priceRange.min > model.priceRange.max) {
            throw new Error('Minimum price cannot be greater than maximum price');
          }
        }
      }

      const brandRef = doc(db, 'brands', editingBrand.id);
      const brandData = {
        id: editingBrand.id,
        name: editingBrand.name,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        isCustom: false,
        series: editingBrand.series
      };

      await setDoc(brandRef, brandData);
      await fetchBrands(); // Refresh the list after saving
      setShowForm(false);
      setEditingBrand(null);
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to save brand');
      console.error('Error saving brand:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleJsonImport = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const importedData = JSON.parse(jsonInput);
      await processImportFile(importedData);
      setJsonInput(''); // Clear input after successful import
    } catch (error) {
      console.error('Error parsing JSON:', error);
      setError(error instanceof Error ? error.message : 'Invalid JSON format');
    } finally {
      setLoading(false);
    }
  };

  const processImportFile = async (importedData: any) => {
    try {
      // Validate the imported data structure
      if (!Array.isArray(importedData)) {
        throw new Error('Imported data must be an array of brands');
      }

      setImportProgress({
        total: importedData.length,
        current: 0,
        status: 'Starting import...'
      });

      // Process each brand
      for (let i = 0; i < importedData.length; i++) {
        const brandData = importedData[i];
        setImportProgress({
          total: importedData.length,
          current: i + 1,
          status: `Importing ${brandData.name}...`
        });

        if (!brandData.name || !Array.isArray(brandData.series)) {
          throw new Error(`Brand at index ${i} must have a name and series array`);
        }

        const brandId = brandData.name.toLowerCase().replace(/[^a-z0-9]+/g, '-');
        const brandRef = doc(db, 'brands', brandId);

        // Check if brand already exists
        const existingBrandDoc = await getDoc(brandRef);
        const existingBrand = existingBrandDoc.exists() ? existingBrandDoc.data() : null;

        // Convert series array to object format for Firestore
        const newSeriesObject = brandData.series.reduce((acc: any, series: any, seriesIndex: number) => {
          if (!series.name || !Array.isArray(series.models)) {
            throw new Error(`Series in brand ${brandData.name} must have a name and models array`);
          }

          // Convert models array to object format
          const modelsObject = series.models.reduce((modelAcc: any, model: any, modelIndex: number) => {
            if (!model.name || !model.description) {
              throw new Error(`Model in series ${series.name} of brand ${brandData.name} must have a name and description`);
            }

            modelAcc[modelIndex.toString()] = {
              id: `${brandId}-${seriesIndex}-${modelIndex}`,
              name: model.name,
              description: model.description,
              specs: model.specs || {},
              priceRange: model.priceRange || { min: 0, max: 0 },
              selected: false,
              productUrl: model.productUrl || ''
            };
            return modelAcc;
          }, {});

          acc[seriesIndex.toString()] = {
            name: series.name,
            models: modelsObject
          };
          return acc;
        }, {});

        let mergedSeriesObject;
        if (existingBrand && existingBrand.series) {
          // If brand exists, merge the series
          const existingSeriesKeys = Object.keys(existingBrand.series);
          const nextSeriesIndex = existingSeriesKeys.length > 0 
            ? Math.max(...existingSeriesKeys.map(k => parseInt(k))) + 1 
            : 0;

          mergedSeriesObject = {
            ...existingBrand.series,
            ...Object.entries(newSeriesObject).reduce((acc, [_, seriesData]) => {
              acc[nextSeriesIndex.toString()] = seriesData;
              return acc;
            }, {} as any)
          };
        } else {
          // If brand doesn't exist, use new series as is
          mergedSeriesObject = newSeriesObject;
        }

        // Save to Firestore with merged data
        await setDoc(brandRef, {
          id: brandId,
          name: brandData.name,
          createdAt: existingBrand?.createdAt || new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          isCustom: existingBrand?.isCustom ?? false,
          series: mergedSeriesObject
        });
      }

      setImportProgress({
        total: importedData.length,
        current: importedData.length,
        status: 'Import completed!'
      });

      await fetchBrands(); // Refresh the list
      
      // Clear progress after a delay
      setTimeout(() => setImportProgress(null), 3000);
    } catch (error) {
      console.error('Error importing brands:', error);
      setError(error instanceof Error ? error.message : 'Failed to import brands');
    }
  };

  const renderForm = () => {
    if (!editingBrand) return null;

    return (
      <form onSubmit={handleSubmit} className="space-y-6">
        <Card>
          <div className="p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold">
                {editingBrand.id ? 'Edit Brand' : 'Add New Brand'}
              </h2>
              <Button
                type="button"
                variant="secondary"
                onClick={() => {
                  setShowForm(false);
                  setEditingBrand(null);
                }}
                className="flex items-center gap-2"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
                Cancel
              </Button>
            </div>

            <div className="grid grid-cols-2 gap-6 mb-8">
              <FormField label="Brand ID" required>
                <input
                  type="text"
                  value={editingBrand.id}
                  onChange={(e) => setEditingBrand({ ...editingBrand, id: e.target.value.toLowerCase().replace(/[^a-z0-9-]/g, '-') })}
                  className="w-full px-3 py-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="brand-name"
                  required
                />
                <p className="mt-1 text-sm text-gray-500">Unique identifier, lowercase with hyphens</p>
              </FormField>

              <FormField label="Brand Name" required>
                <input
                  type="text"
                  value={editingBrand.name}
                  onChange={(e) => setEditingBrand({ ...editingBrand, name: e.target.value })}
                  className="w-full px-3 py-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Brand Name"
                  required
                />
              </FormField>
            </div>

            <div className="space-y-6">
              {Object.entries(editingBrand.series).map(([seriesKey, series]) => (
                <div key={seriesKey} className="border rounded-lg p-4 bg-gray-50">
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="font-medium">Collection {parseInt(seriesKey) + 1}</h3>
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={() => {
                        const newSeries = { ...editingBrand.series };
                        delete newSeries[seriesKey];
                        setEditingBrand({
                          ...editingBrand,
                          series: newSeries
                        });
                      }}
                      className="text-red-600 hover:text-red-700"
                    >
                      Remove Collection
                    </Button>
                  </div>

                  <FormField label="Collection Name" required>
                    <input
                      type="text"
                      value={series.name}
                      onChange={(e) => setEditingBrand({
                        ...editingBrand,
                        series: {
                          ...editingBrand.series,
                          [seriesKey]: { ...series, name: e.target.value }
                        }
                      })}
                      className="w-full px-3 py-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Collection Name"
                      required
                    />
                  </FormField>

                  <div className="mt-4 space-y-4">
                    {Object.entries(series.models).map(([modelKey, model]) => (
                      <div key={modelKey} className="border rounded-lg p-4 bg-white">
                        <div className="flex justify-between items-center mb-4">
                          <h4 className="font-medium">Model {parseInt(modelKey) + 1}</h4>
                          <Button
                            type="button"
                            variant="secondary"
                            onClick={() => {
                              const newModels = { ...series.models };
                              delete newModels[modelKey];
                              setEditingBrand({
                                ...editingBrand,
                                series: {
                                  ...editingBrand.series,
                                  [seriesKey]: {
                                    ...series,
                                    models: newModels
                                  }
                                }
                              });
                            }}
                            className="text-red-600 hover:text-red-700"
                          >
                            Remove Model
                          </Button>
                        </div>

                        <div className="grid grid-cols-2 gap-4">
                          <FormField label="Model Name" required>
                            <input
                              type="text"
                              value={model.name}
                              onChange={(e) => {
                                const updatedModel = { ...model, name: e.target.value };
                                setEditingBrand({
                                  ...editingBrand,
                                  series: {
                                    ...editingBrand.series,
                                    [seriesKey]: {
                                      ...series,
                                      models: {
                                        ...series.models,
                                        [modelKey]: updatedModel
                                      }
                                    }
                                  }
                                });
                              }}
                              className="w-full px-3 py-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                              placeholder="Model Name"
                              required
                            />
                          </FormField>

                          <FormField label="Product URL">
                            <input
                              type="url"
                              value={model.productUrl || ''}
                              onChange={(e) => {
                                const updatedModel = { ...model, productUrl: e.target.value };
                                setEditingBrand({
                                  ...editingBrand,
                                  series: {
                                    ...editingBrand.series,
                                    [seriesKey]: {
                                      ...series,
                                      models: {
                                        ...series.models,
                                        [modelKey]: updatedModel
                                      }
                                    }
                                  }
                                });
                              }}
                              className="w-full px-3 py-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                              placeholder="https://example.com/product"
                            />
                          </FormField>

                          <div className="grid grid-cols-2 gap-4">
                            <FormField label="Min Price" required>
                              <input
                                type="number"
                                value={model.priceRange?.min || 0}
                                onChange={(e) => {
                                  const updatedModel = {
                                    ...model,
                                    priceRange: { 
                                      ...model.priceRange,
                                      min: parseInt(e.target.value) 
                                    }
                                  };
                                  setEditingBrand({
                                    ...editingBrand,
                                    series: {
                                      ...editingBrand.series,
                                      [seriesKey]: {
                                        ...series,
                                        models: {
                                          ...series.models,
                                          [modelKey]: updatedModel
                                        }
                                      }
                                    }
                                  });
                                }}
                                className="w-full px-3 py-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                min="0"
                                required
                              />
                            </FormField>

                            <FormField label="Max Price" required>
                              <input
                                type="number"
                                value={model.priceRange?.max || 0}
                                onChange={(e) => {
                                  const updatedModel = {
                                    ...model,
                                    priceRange: { 
                                      ...model.priceRange,
                                      max: parseInt(e.target.value) 
                                    }
                                  };
                                  setEditingBrand({
                                    ...editingBrand,
                                    series: {
                                      ...editingBrand.series,
                                      [seriesKey]: {
                                        ...series,
                                        models: {
                                          ...series.models,
                                          [modelKey]: updatedModel
                                        }
                                      }
                                    }
                                  });
                                }}
                                className="w-full px-3 py-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                min="0"
                                required
                              />
                            </FormField>
                          </div>

                          <div className="col-span-2">
                            <FormField label="Description" required>
                              <textarea
                                value={model.description}
                                onChange={(e) => {
                                  const updatedModel = { ...model, description: e.target.value };
                                  setEditingBrand({
                                    ...editingBrand,
                                    series: {
                                      ...editingBrand.series,
                                      [seriesKey]: {
                                        ...series,
                                        models: {
                                          ...series.models,
                                          [modelKey]: updatedModel
                                        }
                                      }
                                    }
                                  });
                                }}
                                className="w-full px-3 py-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                rows={4}
                                placeholder="Model description..."
                                required
                              />
                            </FormField>
                          </div>

                          <div className="col-span-2">
                            <FormField label="Specifications">
                              <div className="space-y-2">
                                {Object.entries(model.specs || {}).map(([specKey, specValue], index) => (
                                  <div key={index} className="flex gap-2">
                                    <input
                                      type="text"
                                      value={specKey}
                                      onChange={(e) => {
                                        const newSpecs = { ...model.specs };
                                        delete newSpecs[specKey];
                                        newSpecs[e.target.value] = specValue;
                                        const updatedModel = { ...model, specs: newSpecs };
                                        setEditingBrand({
                                          ...editingBrand,
                                          series: {
                                            ...editingBrand.series,
                                            [seriesKey]: {
                                              ...series,
                                              models: {
                                                ...series.models,
                                                [modelKey]: updatedModel
                                              }
                                            }
                                          }
                                        });
                                      }}
                                      className="w-1/3 px-3 py-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                      placeholder="Spec name"
                                    />
                                    <input
                                      type="text"
                                      value={specValue}
                                      onChange={(e) => {
                                        const updatedModel = {
                                          ...model,
                                          specs: {
                                            ...model.specs,
                                            [specKey]: e.target.value
                                          }
                                        };
                                        setEditingBrand({
                                          ...editingBrand,
                                          series: {
                                            ...editingBrand.series,
                                            [seriesKey]: {
                                              ...series,
                                              models: {
                                                ...series.models,
                                                [modelKey]: updatedModel
                                              }
                                            }
                                          }
                                        });
                                      }}
                                      className="flex-1 px-3 py-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                      placeholder="Spec value"
                                    />
                                    <Button
                                      type="button"
                                      variant="secondary"
                                      onClick={() => {
                                        const newSpecs = { ...model.specs };
                                        delete newSpecs[specKey];
                                        const updatedModel = { ...model, specs: newSpecs };
                                        setEditingBrand({
                                          ...editingBrand,
                                          series: {
                                            ...editingBrand.series,
                                            [seriesKey]: {
                                              ...series,
                                              models: {
                                                ...series.models,
                                                [modelKey]: updatedModel
                                              }
                                            }
                                          }
                                        });
                                      }}
                                      className="text-red-600 hover:text-red-700"
                                    >
                                      Remove
                                    </Button>
                                  </div>
                                ))}
                                <Button
                                  type="button"
                                  variant="secondary"
                                  onClick={() => {
                                    const updatedModel = {
                                      ...model,
                                      specs: {
                                        ...model.specs,
                                        '': ''
                                      }
                                    };
                                    setEditingBrand({
                                      ...editingBrand,
                                      series: {
                                        ...editingBrand.series,
                                        [seriesKey]: {
                                          ...series,
                                          models: {
                                            ...series.models,
                                            [modelKey]: updatedModel
                                          }
                                        }
                                      }
                                    });
                                  }}
                                  className="w-full mt-2"
                                >
                                  Add Specification
                                </Button>
                              </div>
                            </FormField>
                          </div>
                        </div>
                      </div>
                    ))}

                    <Button
                      type="button"
                      variant="secondary"
                      onClick={() => handleAddModel(seriesKey)}
                      className="w-full mt-4"
                    >
                      Add Model
                    </Button>
                  </div>
                </div>
              ))}

              <Button
                type="button"
                variant="secondary"
                onClick={handleAddSeries}
                className="w-full"
              >
                Add Collection
              </Button>
            </div>

            <div className="flex justify-end gap-4 mt-8">
              <Button
                type="submit"
                variant="primary"
                disabled={loading}
                className="flex items-center gap-2"
              >
                {loading ? (
                  <>
                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                    Saving...
                  </>
                ) : (
                  <>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    Save Brand
                  </>
                )}
              </Button>
            </div>
          </div>
        </Card>
      </form>
    );
  };

  const toggleBrandExpansion = (brandId: string) => {
    setExpandedBrands(prev => {
      const newSet = new Set(prev);
      if (newSet.has(brandId)) {
        newSet.delete(brandId);
      } else {
        newSet.add(brandId);
      }
      return newSet;
    });
  };

  return (
    <div className="space-y-6 p-6 max-w-7xl mx-auto">
      <div className="flex justify-between items-center mb-8">
        <div>
          <h1 className="text-2xl font-bold">System Brands Management</h1>
          <p className="text-gray-600 mt-1">Manage mattress brands, collections, and models</p>
        </div>
        <div className="flex gap-4">
          <Button
            variant="primary"
            onClick={handleAddBrand}
            disabled={loading}
            className="flex items-center gap-2"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
            </svg>
            Add Brand
          </Button>
        </div>
      </div>

      {/* JSON Input Section */}
      <Card>
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Import Brands</h2>
            <Button
              variant="secondary"
              onClick={() => setShowJsonExample(true)}
              className="text-sm"
            >
              View Sample Format
            </Button>
          </div>
          <div className="space-y-4">
            <FormField label="JSON Data">
              <textarea
                value={jsonInput}
                onChange={(e) => setJsonInput(e.target.value)}
                className="w-full h-64 px-3 py-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500 font-mono text-sm"
                placeholder="Paste your JSON data here..."
              />
            </FormField>
            <div className="flex justify-end gap-2">
              <Button
                variant="secondary"
                onClick={() => setJsonInput('')}
                disabled={!jsonInput || loading}
              >
                Clear
              </Button>
              <Button
                variant="primary"
                onClick={handleJsonImport}
                disabled={!jsonInput || loading}
                className="flex items-center gap-2"
              >
                {loading ? (
                  <>
                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                    Importing...
                  </>
                ) : (
                  <>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z" clipRule="evenodd" />
                    </svg>
                    Import
                  </>
                )}
              </Button>
            </div>
          </div>

          {/* Import Progress */}
          {importProgress && (
            <div className="mt-4">
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div
                  className="bg-blue-600 h-2.5 rounded-full transition-all duration-300"
                  style={{ width: `${(importProgress.current / importProgress.total) * 100}%` }}
                ></div>
              </div>
              <p className="mt-2 text-sm text-gray-600">
                {importProgress.status} ({importProgress.current}/{importProgress.total})
              </p>
            </div>
          )}
        </div>
      </Card>

      {/* JSON Example Modal */}
      {showJsonExample && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg max-w-3xl w-full max-h-[80vh] overflow-y-auto">
            <div className="p-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold">Sample JSON Format</h3>
                <button
                  onClick={() => setShowJsonExample(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <div className="prose">
                <p className="text-sm text-gray-600 mb-4">
                  Your JSON file should follow this structure. All fields shown are required unless marked as optional.
                </p>
                <pre className="bg-gray-50 p-4 rounded-lg overflow-x-auto">
                  <code className="text-sm">
                    {JSON.stringify(sampleJson, null, 2)}
                  </code>
                </pre>
                <div className="mt-4 text-sm text-gray-600">
                  <h4 className="font-medium mb-2">Field Descriptions:</h4>
                  <ul className="list-disc pl-5 space-y-1">
                    <li><code>name</code>: Brand or model name</li>
                    <li><code>description</code>: Detailed description of the model</li>
                    <li><code>specs</code>: Object containing key-value pairs of specifications</li>
                    <li><code>priceRange</code>: Object with min and max prices in USD</li>
                    <li><code>productUrl</code>: (Optional) Link to product page</li>
                  </ul>
                </div>
                <div className="mt-4 flex justify-end">
                  <Button
                    variant="secondary"
                    onClick={() => {
                      navigator.clipboard.writeText(JSON.stringify(sampleJson, null, 2));
                      alert('Sample JSON copied to clipboard!');
                    }}
                    className="mr-2"
                  >
                    Copy to Clipboard
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => setShowJsonExample(false)}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {error && (
        <div className="bg-red-50 text-red-600 p-4 rounded mb-6 flex items-center gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
          </svg>
          {error}
        </div>
      )}

      {loading && (
        <div className="flex justify-center items-center py-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
        </div>
      )}

      {showForm ? (
        renderForm()
      ) : (
        <div className="grid gap-6">
          {brands.map(brand => (
            <Card key={brand.id} className="overflow-hidden hover:shadow-lg transition-shadow duration-200">
              <div className="p-6">
                <div className="flex justify-between items-start mb-6">
                  <div className="flex-1">
                    <div className="flex items-center gap-3">
                      <button
                        onClick={() => toggleBrandExpansion(brand.id)}
                        className="text-gray-500 hover:text-gray-700 focus:outline-none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className={`h-5 w-5 transform transition-transform ${
                            expandedBrands.has(brand.id) ? 'rotate-90' : ''
                          }`}
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                      <h2 className="text-xl font-semibold">{brand.name}</h2>
                      {brand.isCustom ? (
                        <span className="px-2 py-1 text-xs bg-blue-50 text-blue-600 rounded-full">
                          Custom Brand
                        </span>
                      ) : (
                        <span className="px-2 py-1 text-xs bg-purple-50 text-purple-600 rounded-full">
                          System Brand
                        </span>
                      )}
                    </div>
                    <p className="text-gray-500 text-sm mt-1">ID: {brand.id}</p>
                    <p className="text-gray-500 text-sm">
                      Last Updated: {new Date(brand.updatedAt).toLocaleDateString()}
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <Button
                      variant="secondary"
                      onClick={() => handleEditBrand(brand)}
                      disabled={loading}
                      className="flex items-center gap-2"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                      </svg>
                      Edit
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={() => handleDeleteBrand(brand.id)}
                      disabled={loading}
                      className="flex items-center gap-2 bg-red-600 text-white hover:bg-red-700"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                      </svg>
                      Delete
                    </Button>
                  </div>
                </div>

                {expandedBrands.has(brand.id) && (
                  <div className="mt-6 space-y-6">
                    {brand.series.map((series, index) => (
                      <div key={index} className="border-t pt-4">
                        <h3 className="font-medium text-lg mb-3 flex items-center gap-2">
                          <span>{series.name}</span>
                          <span className="text-sm text-gray-500">({series.models.length} models)</span>
                        </h3>
                        <div className="grid gap-4">
                          {series.models.map((model, modelIndex) => (
                            <div key={modelIndex} className="bg-gray-50 p-4 rounded-lg hover:bg-gray-100 transition-colors duration-200">
                              <div className="flex justify-between items-start">
                                <div className="flex-grow">
                                  <h4 className="font-medium text-gray-900">{model.name}</h4>
                                  <p className="text-sm text-gray-600 mt-1 line-clamp-2">{model.description}</p>
                                  {model.specs && Object.keys(model.specs).length > 0 && (
                                    <div className="mt-2 flex flex-wrap gap-2">
                                      {Object.entries(model.specs).map(([key, value]) => (
                                        <span key={key} className="px-2 py-1 bg-white text-xs text-gray-600 rounded border">
                                          {key}: {value}
                                        </span>
                                      ))}
                                    </div>
                                  )}
                                </div>
                                <div className="text-right ml-4">
                                  <div className="text-sm font-medium text-gray-900">
                                    ${model.priceRange?.min.toLocaleString()} - ${model.priceRange?.max.toLocaleString()}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Card>
          ))}
        </div>
      )}
    </div>
  );
};

export default AdminBrandsPage; 
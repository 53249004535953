/**
 * Authentication Store
 * 
 * Global state management for user authentication using Zustand.
 * Handles Firebase Authentication integration, Google Sign-In,
 * and synchronization with Firestore user data.
 * 
 * Key Features:
 * - User authentication state management
 * - Google OAuth integration
 * - Token refresh and management
 * - Role-based access control
 * - Firestore user data synchronization
 */

import { create } from 'zustand';
import { User } from '../types/auth';
import { 
  signInWithEmailAndPassword, 
  signOut,
  onAuthStateChanged,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  updateProfile,
  sendEmailVerification
} from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { auth, db } from '../config/firebase';
import { useOnboardingStore } from './onboardingStore';
import { setupMerchantChat } from '../services/api/merchantConfigApi';

/**
 * Google OAuth Provider Configuration
 * 
 * Sets up Google authentication with required scopes:
 * - Email access for user identification
 * - Profile access for user details
 */
const googleProvider = new GoogleAuthProvider();
googleProvider.addScope('https://www.googleapis.com/auth/userinfo.email');
googleProvider.addScope('https://www.googleapis.com/auth/userinfo.profile');

/**
 * Authentication State Interface
 * 
 * @property {User | null} user - Current authenticated user or null
 * @property {boolean} isLoading - Loading state during auth operations
 * @property {boolean} isAuthenticated - Whether a user is logged in
 * @property {function} setUser - Updates the current user
 * @property {function} setLoading - Updates loading state
 * @property {function} logout - Handles user logout
 * @property {function} signInWithGoogle - Handles Google OAuth sign-in
 * @property {function} refreshToken - Forces a token refresh
 * @property {function} signUp - Handles user sign-up
 * @property {function} resetPassword - Handles password reset
 */
interface AuthState {
  user: User | null;
  isLoading: boolean;
  isAuthenticated: boolean;
  setUser: (user: User | null) => void;
  setLoading: (loading: boolean) => void;
  logout: () => Promise<void>;
  signInWithGoogle: () => Promise<void>;
  refreshToken: () => Promise<void>;
  signUp: (data: SignUpData) => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
}

interface SignUpData {
  email: string;
  password: string;
  name: string;
  companyName: string;
}

/**
 * Authentication Store Implementation
 * 
 * Uses Zustand for state management with the following features:
 * 1. User State:
 *    - Tracks current user
 *    - Manages authentication status
 *    - Handles loading states
 * 
 * 2. Authentication Actions:
 *    - Google Sign-in
 *    - Logout
 *    - Token refresh
 * 
 * 3. Data Synchronization:
 *    - Firestore user document creation/updates
 *    - Role management
 *    - Token claim verification
 */
export const useAuthStore = create<AuthState>((set) => ({
  // Initial state
  user: null,
  isLoading: true,
  isAuthenticated: false,

  // State setters
  setUser: (user) => set({ 
    user, 
    isAuthenticated: !!user,
  }),
  setLoading: (loading) => set({ isLoading: loading }),

  /**
   * Logout Handler
   * Signs out the user and clears the auth state
   */
  logout: async () => {
    try {
      await signOut(auth);
      set({ user: null, isAuthenticated: false });
    } catch (error) {
      console.error('Logout failed:', error);
      throw error;
    }
  },

  /**
   * Google Sign-in Handler
   * 
   * Process:
   * 1. Initiates Google OAuth flow
   * 2. Creates/updates Firestore user document
   * 3. Fetches additional user data and token claims
   * 4. Updates auth state with combined user data
   */
  signInWithGoogle: async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      
      // Check for existing user document
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      console.log('User document:', userDoc.data()); // Debug log
      
      // Handle new user setup
      if (!userDoc.exists()) {
        // Reset onboarding for new users
        useOnboardingStore.getState().resetOnboarding();
        
        // Create initial user document
        const userData = {
          email: user.email,
          name: user.displayName || 'New User',
          companyName: user.displayName || 'New Store', // Use displayName as company name initially, with fallback
          role: 'merchant', // Default role
          createdAt: new Date().toISOString()
        };
        console.log('Creating new user with data:', userData);
        await setDoc(doc(db, 'users', user.uid), userData);
      }
      
      // Fetch latest user data and token
      const latestUserDoc = await getDoc(doc(db, 'users', user.uid));
      const userData = latestUserDoc.data();
      const idTokenResult = await user.getIdTokenResult(true);
      
      // Update auth state with combined data
      set({ 
        user: {
          ...user,
          role: idTokenResult.claims.role || userData?.role || 'merchant'
        } as User, 
        isAuthenticated: true 
      });
    } catch (error) {
      console.error('Google sign in failed:', error);
      throw error;
    }
  },

  /**
   * Token Refresh Handler
   * 
   * Forces a refresh of the Firebase ID token and updates user data:
   * 1. Verifies current user exists
   * 2. Forces token refresh
   * 3. Fetches latest user data and claims
   * 4. Updates auth state with new information
   */
  refreshToken: async () => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('No user is currently signed in');
      }

      // Force token refresh
      await currentUser.getIdToken(true);
      
      // Get latest data
      const [idTokenResult, userDoc] = await Promise.all([
        currentUser.getIdTokenResult(),
        getDoc(doc(db, 'users', currentUser.uid))
      ]);
      
      const userData = userDoc.data();
      
      // Update store with latest data
      set({
        user: {
          ...currentUser,
          role: idTokenResult.claims.role || userData?.role || 'merchant',
          merchantId: userData?.merchantId,
        } as User,
        isAuthenticated: true
      });
    } catch (error) {
      console.error('Token refresh failed:', error);
      throw error;
    }
  },

  signUp: async (data: SignUpData) => {
    const { user } = await createUserWithEmailAndPassword(auth, data.email, data.password);
    
    await updateProfile(user, { displayName: data.name });
    
    await setDoc(doc(db, 'users', user.uid), {
      email: data.email,
      name: data.name,
      companyName: data.companyName,
      role: 'merchant',
      createdAt: new Date().toISOString()
    });

    await sendEmailVerification(user);
    
    set({ user, isAuthenticated: true });
  },

  resetPassword: async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      console.error('Password reset failed:', error);
      throw error;
    }
  }
}));

/**
 * Firebase Auth State Listener
 * 
 * Monitors authentication state changes and:
 * 1. Updates loading state
 * 2. Fetches additional user data
 * 3. Handles new user detection
 * 4. Updates global auth state
 * 
 * This runs on:
 * - Application initialization
 * - User sign-in/sign-out
 * - Token refresh
 */
onAuthStateChanged(auth, async (firebaseUser) => {
  useAuthStore.getState().setLoading(true);
  
  if (firebaseUser) {
    try {
      // Fetch user data and token claims
      const [userDoc, idTokenResult] = await Promise.all([
        getDoc(doc(db, 'users', firebaseUser.uid)),
        firebaseUser.getIdTokenResult()
      ]);
      const userData = userDoc.data();
      
      // Handle new user detection
      if (!userDoc.exists()) {
        useOnboardingStore.getState().resetOnboarding();
      }
      
      // Update auth state with combined data
      useAuthStore.getState().setUser({
        ...firebaseUser,
        role: idTokenResult.claims.role || userData?.role || 'merchant',
        merchantId: userData?.merchantId,
      } as User);
    } catch (error) {
      console.error('Error fetching user data:', error);
      useAuthStore.getState().setUser(null);
    }
  } else {
    useAuthStore.getState().setUser(null);
  }
  
  useAuthStore.getState().setLoading(false);
});
import { create } from 'zustand';

export type InventoryMethod = 'system-brands' | 'custom-brand';

interface QuickStartState {
  isOpen: boolean;
  selectedMethod: InventoryMethod | null;
  setOpen: (isOpen: boolean) => void;
  setSelectedMethod: (method: InventoryMethod) => void;
  reset: () => void;
}

export const useQuickStartStore = create<QuickStartState>((set) => ({
  isOpen: false,
  selectedMethod: null,
  setOpen: (isOpen) => set({ isOpen }),
  setSelectedMethod: (method) => set({ selectedMethod: method }),
  reset: () => set({ isOpen: false, selectedMethod: null }),
})); 
import React from 'react';
// Test deployment - GitHub Actions should deploy this automatically
/**
 * Main Application Component
 * Handles routing, authentication, and layout structure of the application.
 * Implements lazy loading for optimal performance and code splitting.
 */

import { Suspense, lazy } from 'react';
import { createBrowserRouter, RouterProvider, Navigate, type RouterProviderProps } from 'react-router-dom';
import { useAuthStore } from './stores/authStore';
import { useOnboardingStore } from './stores/onboardingStore';
import { QueryProvider } from './providers/QueryProvider';
import Layout from './components/layout/Layout';
import { adminRoutes } from './routes/adminRoutes';
import AdminBrandsPage from './components/admin/AdminBrandsPage';
import { Toaster } from 'react-hot-toast';
import OnboardingPopup from './components/onboarding/OnboardingPopup';

// Lazy loaded components for code splitting and performance optimization
const Login = lazy(() => import('./pages/Login'));
const SignUp = lazy(() => import('./components/auth/SignUpPage'));
const HomePage = lazy(() => import('./components/home/HomePage'));
const ChatConfig = lazy(() => import('./pages/ChatConfig'));
const Analytics = lazy(() => import('./pages/Analytics'));
const Sessions = lazy(() => import('./pages/Sessions'));
const SessionDetail = lazy(() => import('./pages/SessionDetail'));
const Share = lazy(() => import('./pages/Share'));
const Settings = lazy(() => import('./components/settings/SettingsPage'));
const Brands = lazy(() => import('./pages/Brands'));
const AssistantConfig = lazy(() => import('./pages/AssistantConfig'));
const Membership = lazy(() => import('./pages/Membership'));
const Feedback = lazy(() => import('./pages/Feedback'));
const Tutorials = lazy(() => import('./pages/Tutorials'));

/**
 * Loading spinner component displayed during suspense states
 * Used as a fallback while lazy-loaded components are being fetched
 */
const Loading = () => (
  <div className="flex items-center justify-center h-screen">
    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
  </div>
);

/**
 * Root application component that sets up:
 * - Authentication-based routing
 * - Protected routes
 * - Lazy loading with suspense
 * - Global toast notifications
 * - Onboarding flow for new users
 */
function App() {
  const { user, isAuthenticated, isLoading } = useAuthStore();
  const { isComplete } = useOnboardingStore();

  // Display loading spinner while authentication state is being determined
  if (isLoading) {
    return <Loading />;
  }

  // Router configuration with protected routes and authentication checks
  const router = createBrowserRouter([
    // Admin section routes
    {
      path: '/admin',
      children: [
        {
          path: 'brands',
          element: (
            <Suspense fallback={<Loading />}>
              <AdminBrandsPage />
            </Suspense>
          ),
        }
      ]
    },
    // Main application routes - protected by authentication
    {
      path: '/',
      element: isAuthenticated ? <Layout /> : <Navigate to="/login" replace />,
      children: [
        {
          path: '/',
          element: (
            <Suspense fallback={<Loading />}>
              <HomePage />
            </Suspense>
          ),
        },
        {
          path: '/sessions',
          element: (
            <Suspense fallback={<Loading />}>
              <Sessions />
            </Suspense>
          ),
        },
        {
          path: '/sessions/:id',
          element: (
            <Suspense fallback={<Loading />}>
              <SessionDetail />
            </Suspense>
          ),
        },
        {
          path: '/analytics',
          element: (
            <Suspense fallback={<Loading />}>
              <Analytics />
            </Suspense>
          ),
        },
        {
          path: '/share',
          element: (
            <Suspense fallback={<Loading />}>
              <Share />
            </Suspense>
          ),
        },
        {
          path: '/settings',
          element: (
            <Suspense fallback={<Loading />}>
              <Settings />
            </Suspense>
          ),
        },
        {
          path: '/brands',
          element: (
            <Suspense fallback={<Loading />}>
              <Brands />
            </Suspense>
          ),
        },
        {
          path: '/assistant-config',
          element: (
            <Suspense fallback={<Loading />}>
              <AssistantConfig />
            </Suspense>
          ),
        },
        {
          path: '/membership',
          element: (
            <Suspense fallback={<Loading />}>
              <Membership />
            </Suspense>
          ),
        },
        {
          path: '/feedback',
          element: (
            <Suspense fallback={<Loading />}>
              <Feedback />
            </Suspense>
          ),
        },
        {
          path: '/tutorials',
          element: (
            <Suspense fallback={<Loading />}>
              <Tutorials />
            </Suspense>
          ),
        },
      ],
    },
    // Public authentication routes
    {
      path: '/login',
      element: !isAuthenticated ? (
        <Suspense fallback={<Loading />}>
          <Login />
        </Suspense>
      ) : (
        <Navigate to="/" replace />
      ),
    },
    {
      path: '/signup',
      element: !isAuthenticated ? (
        <Suspense fallback={<Loading />}>
          <SignUp />
        </Suspense>
      ) : (
        <Navigate to="/" replace />
      ),
    },
  ], {
    future: {
      v7_normalizeFormMethod: true,
      v7_relativeSplatPath: true
    }
  });

  // Wrap the application with query provider and toast notifications
  return (
    <QueryProvider>
      <RouterProvider router={router} />
      {isAuthenticated && !isComplete && <OnboardingPopup />}
      <Toaster position="bottom-right" />
    </QueryProvider>
  );
}

export default App;
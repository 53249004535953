import React from 'react';
import { X, Minus, MessageSquare } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { Config } from '@mattressai/config';

interface ChatPreviewProps {
  isOpen: boolean;
  onClose: () => void;
  config: Config;
}

export default function ChatPreview({ isOpen, onClose, config }: ChatPreviewProps) {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          className="fixed bottom-4 right-4 w-96 bg-white rounded-lg shadow-xl overflow-hidden"
          style={{ maxHeight: '80vh' }}
        >
          {/* Chat Header */}
          <div 
            className="flex items-center justify-between p-4"
            style={{ backgroundColor: config.branding.appearance.colors.primary }}
          >
            <div className="flex items-center space-x-2">
              {config.branding.appearance.logo ? (
                <img
                  src={config.branding.appearance.logo}
                  alt="Logo"
                  className="w-6 h-6 rounded"
                />
              ) : (
                <MessageSquare className="w-6 h-6 text-white" />
              )}
              <h2 className="text-white font-medium">
                {config.branding.appearance.chatTitle || config.branding.store.name || 'Chat'}
              </h2>
            </div>
            <div className="flex items-center space-x-2">
              <button
                onClick={() => {}}
                className="text-white hover:text-gray-200 transition-colors"
              >
                <Minus className="w-4 h-4" />
              </button>
              <button
                onClick={onClose}
                className="text-white hover:text-gray-200 transition-colors"
              >
                <X className="w-4 h-4" />
              </button>
            </div>
          </div>

          {/* Chat Messages */}
          <div className="p-4 h-96 overflow-y-auto space-y-4">
            {/* Assistant Message */}
            <div className="flex items-start space-x-2">
              {config.branding.appearance.logo ? (
                <img
                  src={config.branding.appearance.logo}
                  alt="Assistant"
                  className="w-8 h-8 rounded mt-1"
                />
              ) : (
                <div 
                  className="w-8 h-8 rounded mt-1 flex items-center justify-center"
                  style={{ backgroundColor: config.branding.appearance.colors.primary }}
                >
                  <MessageSquare className="w-5 h-5 text-white" />
                </div>
              )}
              <div className="flex-1">
                <div className="bg-gray-100 rounded-lg p-3 inline-block">
                  <p className="text-sm text-gray-800">
                    {config.chat.interface.welcomeMessage}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Chat Input */}
          <div className="p-4 border-t">
            <div className="flex items-center space-x-2">
              <input
                type="text"
                placeholder="Type your message..."
                className="flex-1 p-2 border rounded-lg focus:outline-none focus:ring-2"
                style={{ 
                  borderColor: config.branding.appearance.colors.primary,
                  '--tw-ring-color': config.branding.appearance.colors.primary
                } as React.CSSProperties}
                disabled
              />
              <button
                className="p-2 rounded-lg text-white"
                style={{ backgroundColor: config.branding.appearance.colors.primary }}
                disabled
              >
                Send
              </button>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
} 
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Building2, Plus, ArrowRight, Check } from 'lucide-react';
import { useQuickStartStore } from '../../stores/quickStartStore';
import Button from '../ui/Button';
import { InventoryMethod } from '../../stores/quickStartStore';

const inventorySetupOptions = [
  {
    title: "Use System Brands",
    description: "Choose from our curated list of popular mattress brands and models",
    action: "system-brands" as InventoryMethod,
    icon: Building2,
  },
  {
    title: "Add Custom Brand",
    description: "Create your own brand and add custom mattress models",
    action: "custom-brand" as InventoryMethod,
    icon: Plus,
  }
];

export default function QuickStartGuide() {
  const { isOpen, selectedMethod, setOpen, setSelectedMethod } = useQuickStartStore();

  const handleMethodSelect = (method: InventoryMethod) => {
    setSelectedMethod(method);
  };

  const handleClose = () => {
    setOpen(false);
    // The parent component will handle any necessary navigation
    // after the onboarding is complete
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 bg-black/20 backdrop-blur-sm z-50 flex items-center justify-center p-4">
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            className="bg-white rounded-xl shadow-2xl w-full max-w-2xl overflow-hidden"
          >
            {/* Header */}
            <div className="p-6 border-b">
              <div className="flex items-center justify-between">
                <div>
                  <h2 className="text-xl font-semibold text-gray-900">
                    Set Up Your Inventory
                  </h2>
                  <p className="mt-1 text-sm text-gray-500">
                    Choose how you'd like to manage your mattress inventory
                  </p>
                </div>
                <button
                  onClick={handleClose}
                  className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
                >
                  <X className="w-5 h-5 text-gray-500" />
                </button>
              </div>
            </div>

            {/* Options */}
            <div className="p-6 space-y-4">
              {inventorySetupOptions.map((option) => (
                <button
                  key={option.action}
                  onClick={() => handleMethodSelect(option.action)}
                  className={`w-full p-4 rounded-lg border-2 transition-all flex items-center gap-4 hover:border-blue-500 hover:bg-blue-50 ${
                    selectedMethod === option.action
                      ? 'border-blue-500 bg-blue-50'
                      : 'border-gray-200'
                  }`}
                >
                  <div className="flex-shrink-0">
                    <option.icon className={`w-6 h-6 ${
                      selectedMethod === option.action
                        ? 'text-blue-500'
                        : 'text-gray-400'
                    }`} />
                  </div>
                  <div className="flex-grow text-left">
                    <h3 className="font-medium text-gray-900">{option.title}</h3>
                    <p className="text-sm text-gray-500">{option.description}</p>
                  </div>
                  <div className="flex-shrink-0">
                    {selectedMethod === option.action ? (
                      <Check className="w-5 h-5 text-blue-500" />
                    ) : (
                      <ArrowRight className="w-5 h-5 text-gray-400" />
                    )}
                  </div>
                </button>
              ))}
            </div>

            {/* Info Box */}
            <div className="px-6 pb-6">
              <div className="bg-blue-50 rounded-lg p-4">
                <h4 className="text-sm font-medium text-blue-900 mb-2">Quick Tip</h4>
                <p className="text-sm text-blue-700">
                  You can use both system brands and create custom brands. Start with system brands for popular mattresses, then add custom brands for unique products.
                </p>
              </div>
            </div>

            {/* Footer */}
            <div className="p-6 bg-gray-50 border-t">
              <div className="flex justify-between items-center">
                <button
                  onClick={handleClose}
                  className="text-sm text-gray-500 hover:text-gray-700"
                >
                  I'll do this later
                </button>
                <Button
                  variant="primary"
                  onClick={handleClose}
                  disabled={!selectedMethod}
                >
                  Continue to Inventory
                </Button>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
} 
/**
 * Authentication Hook Module
 * Custom hook for managing authentication state and user roles
 * Provides role-based access control helpers
 */

import { useAuthStore } from '../stores/authStore';

/**
 * useAuth Hook
 * Manages authentication state and provides role-based access control
 * 
 * @returns {Object} Authentication state and role checks
 * @property {User | null} user - Current user object
 * @property {boolean} isLoading - Loading state of auth
 * @property {boolean} isAuthenticated - Whether user is authenticated
 * @property {Function} logout - Function to log out user
 * @property {boolean} isSuperAdmin - Whether user has super admin privileges
 * @property {boolean} isAdmin - Whether user has admin privileges
 * @property {boolean} isMerchant - Whether user has merchant privileges
 * 
 * @example
 * const { user, isAuthenticated, isAdmin } = useAuth();
 * if (isAdmin) {
 *   // Show admin features
 * }
 */
export const useAuth = () => {
  const { user, isLoading, isAuthenticated, logout } = useAuthStore();
  
  // Role-based access control flags
  const isSuperAdmin = user?.role === 'super_admin';
  const isAdmin = user?.role === 'admin' || isSuperAdmin;     // Admins include super admins
  const isMerchant = user?.role === 'merchant' || isAdmin;    // Merchants include admins

  return {
    user,            // Current user object
    isLoading,       // Auth loading state
    isAuthenticated, // Auth state
    logout,          // Logout function
    isSuperAdmin,    // Super admin check
    isAdmin,         // Admin check
    isMerchant,      // Merchant check
  };
};
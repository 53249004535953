/**
 * Form Field Component
 * A wrapper component for form inputs with label and error handling
 * Provides consistent styling and layout for form elements
 */

import React from 'react';

/**
 * Form Field Props Interface
 * @property {string} label - Label text for the form field
 * @property {React.ReactNode} children - Form control to be wrapped (input, select, etc.)
 * @property {string} [error] - Error message to display below the field
 * @property {string} [className] - Additional CSS classes to apply
 */
interface FormFieldProps {
  label: string;
  children: React.ReactNode;
  error?: string;
  className?: string;
}

/**
 * Form Field Component
 * Renders a form field with label and optional error message
 * 
 * @example
 * <FormField label="Email" error={errors.email}>
 *   <input type="email" name="email" />
 * </FormField>
 */
const FormField: React.FC<FormFieldProps> = ({ label, children, error, className = '' }) => {
  return (
    <div className={`space-y-1 ${className}`}>
      {/* Field label */}
      <label className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      {/* Form control */}
      {children}
      {/* Error message */}
      {error && (
        <p className="text-sm text-red-600">{error}</p>
      )}
    </div>
  );
};

export default FormField;
import React from 'react';
import { useOnboardingStore } from '../../../stores/onboardingStore';
import FormField from '../../ui/FormField';

export default function AssistantStep() {
  const { config, updateConfig } = useOnboardingStore();
  const { assistant } = config;

  const handleChange = (field: keyof typeof assistant, value: string) => {
    updateConfig({
      assistant: {
        ...config.assistant,
        [field]: value
      }
    });
  };

  return (
    <div className="space-y-6">
      <FormField label="Assistant Name">
        <input
          type="text"
          className="w-full px-4 py-2 border rounded-lg border-gray-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          placeholder="MattressAI"
          value={assistant.name}
          onChange={(e) => handleChange('name', e.target.value)}
        />
        <p className="mt-1 text-sm text-gray-500">
          The name your AI assistant will use when chatting with customers
        </p>
      </FormField>

      <FormField label="Company Description">
        <textarea
          className="w-full px-4 py-2 border rounded-lg border-gray-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 h-24 resize-none"
          placeholder="You work at MattressAI. You communicate with shoppers and help them find the right mattress for them."
          value={assistant.description}
          onChange={(e) => handleChange('description', e.target.value)}
        />
        <p className="mt-1 text-sm text-gray-500">
          Help the AI understand your company's role and mission
        </p>
      </FormField>

      <FormField label="Assistant Persona">
        <textarea
          className="w-full px-4 py-2 border rounded-lg border-gray-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 h-32 resize-none"
          placeholder="You are helpful and detailed. You have extensive knowledge about mattresses, sleep science, and customer service."
          value={assistant.persona}
          onChange={(e) => handleChange('persona', e.target.value)}
        />
        <p className="mt-1 text-sm text-gray-500">
          Define your assistant's personality and communication style
        </p>
      </FormField>

      <div className="bg-blue-50 rounded-lg p-4">
        <h4 className="text-sm font-medium text-blue-900 mb-2">Personality Tips</h4>
        <div className="space-y-2 text-sm text-blue-700">
          <p>• Be clear and concise in your descriptions</p>
          <p>• Include specific mattress expertise and knowledge</p>
          <p>• Define a consistent tone (friendly, professional, casual)</p>
          <p>• Consider your target customer demographic</p>
          <p>• Include any specific phrases or terminology to use/avoid</p>
        </div>
      </div>
    </div>
  );
} 
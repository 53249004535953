import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';

export interface SubdomainCreationResult {
  success: boolean;
  subdomain: string;
  chatUrl: string;
  error?: string;
}

export async function createSubdomain(
  userId: string,
  storeName: string
): Promise<SubdomainCreationResult> {
  try {
    const subdomain = storeName.toLowerCase().replace(/[^a-z0-9]/g, '-');
    const chatUrl = `${subdomain}.chat.mattressai.co`;

    // Check if subdomain already exists
    const subdomainDoc = await getDoc(doc(db, 'subdomains', subdomain));
    if (subdomainDoc.exists()) {
      return {
        success: false,
        subdomain,
        chatUrl,
        error: 'Subdomain already exists'
      };
    }

    // Create subdomain mapping
    await setDoc(doc(db, 'subdomains', subdomain), {
      merchantId: userId,
      subdomain,
      status: 'active',
      createdAt: new Date(),
      storeName,
      chatUrl,
      features: {
        chatEnabled: true
      }
    });

    // Create reverse lookup
    await setDoc(doc(db, 'merchants', userId, 'subdomains', subdomain), {
      subdomain,
      status: 'active',
      createdAt: new Date(),
      chatUrl
    });

    return {
      success: true,
      subdomain,
      chatUrl
    };
  } catch (error) {
    console.error('Failed to create subdomain:', error);
    return {
      success: false,
      subdomain: '',
      chatUrl: '',
      error: error instanceof Error ? error.message : 'Failed to create subdomain'
    };
  }
}

export async function validateSubdomain(subdomain: string): Promise<boolean> {
  try {
    const subdomainDoc = await getDoc(doc(db, 'subdomains', subdomain));
    return !subdomainDoc.exists();
  } catch (error) {
    console.error('Failed to validate subdomain:', error);
    return false;
  }
}

export async function getSubdomainInfo(subdomain: string) {
  try {
    const subdomainDoc = await getDoc(doc(db, 'subdomains', subdomain));
    if (!subdomainDoc.exists()) {
      return null;
    }
    return subdomainDoc.data();
  } catch (error) {
    console.error('Failed to get subdomain info:', error);
    return null;
  }
} 
/**
 * Button Component
 * A customizable button component with variants, sizes, and loading state
 * Built with Tailwind CSS for styling and supports all native button attributes
 */

import React from 'react';

/**
 * Button Props Interface
 * Extends native button attributes with custom styling options
 * 
 * @property {string} [variant='primary'] - Visual style variant
 * @property {string} [size='md'] - Size variant
 * @property {boolean} [isLoading=false] - Loading state with spinner
 * @property {React.ReactNode} children - Button content
 */
interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'danger';
  size?: 'sm' | 'md' | 'lg';
  isLoading?: boolean;
  children: React.ReactNode;
}

/**
 * Button Component
 * Renders a customizable button with consistent styling
 * 
 * @example
 * <Button variant="primary" size="lg" isLoading={true}>
 *   Save Changes
 * </Button>
 */
const Button: React.FC<ButtonProps> = ({
  variant = 'primary',
  size = 'md',
  isLoading = false,
  children,
  className = '',
  disabled,
  ...props
}) => {
  // Base styles applied to all button variants
  const baseStyles = 'inline-flex items-center justify-center font-medium rounded-lg transition-colors';
  
  // Variant-specific styles with hover and active states
  const variantStyles = {
    primary: 'bg-blue-600 text-white hover:bg-blue-700 active:bg-blue-800',
    secondary: 'bg-gray-100 text-gray-900 hover:bg-gray-200 active:bg-gray-300',
    danger: 'bg-red-600 text-white hover:bg-red-700 active:bg-red-800'
  };

  // Size-specific padding and text styles
  const sizeStyles = {
    sm: 'px-3 py-1.5 text-sm',
    md: 'px-4 py-2 text-sm',
    lg: 'px-6 py-3 text-base'
  };

  // Disabled state styles
  const disabledStyles = disabled || isLoading
    ? 'opacity-50 cursor-not-allowed'
    : '';

  return (
    <button
      className={`
        ${baseStyles}
        ${variantStyles[variant]}
        ${sizeStyles[size]}
        ${disabledStyles}
        ${className}
      `}
      disabled={disabled || isLoading}
      {...props}
    >
      {isLoading ? (
        <>
          {/* Loading spinner SVG */}
          <svg
            className="animate-spin -ml-1 mr-2 h-4 w-4 text-current"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
          Loading...
        </>
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
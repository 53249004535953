import { MerchantConfig, ChatConfig } from '../../types/config';

export const DEFAULT_CHAT_CONFIG: ChatConfig = {
  welcomeMessage: 'Welcome! How can I help you find your perfect mattress today?',
  aiPersonality: 'professional and helpful',
  model: 'gpt-4',
  temperature: 0.7,
  maxTokens: 2000,
  initialGreeting: 'Hello! I\'m your AI mattress expert. How can I help you today?',
  toneDirective: 'professional and helpful',
  conversationStyle: 'professional',
  leadCaptureTiming: 'after-engagement',
  primaryGoal: 'lead',
  responseCreativity: 0.7,
  productRecommendationLimit: 3
};

export const DEFAULT_BRANDING = {
  colors: {
    primary: '#2563eb',
    secondary: '#64748b'
  },
  chatTitle: 'How can we help you find your perfect mattress?',
  welcomeMessage: 'Welcome! I\'m here to help you find the perfect mattress.',
  logo: 'https://firebasestorage.googleapis.com/v0/b/mattressai2.firebasestorage.app/o/default-logo.png?alt=media&token=d19ea3eb-1c91-41d4-b193-4f65c76989cc',
  chatIcon: 'https://firebasestorage.googleapis.com/v0/b/mattressai2.firebasestorage.app/o/default-logo.png?alt=media&token=d19ea3eb-1c91-41d4-b193-4f65c76989cc',
  favicon: 'https://firebasestorage.googleapis.com/v0/b/mattressai2.firebasestorage.app/o/default-logo.png?alt=media&token=d19ea3eb-1c91-41d4-b193-4f65c76989cc'
};

export const DEFAULT_ASSISTANT = {
  name: 'MattressAI',
  description: 'Your AI-powered mattress shopping assistant',
  persona: 'Professional, friendly, and knowledgeable about mattresses',
  greeting: 'Hello! I\'m your AI mattress expert. How can I help you today?'
};

export const DEFAULT_FEATURES = {
  chatEnabled: true,
  recommendationsEnabled: true,
  analyticsEnabled: true
};

export function generateInitialConfig(storeName: string): MerchantConfig {
  const subdomain = storeName.toLowerCase().replace(/[^a-z0-9]/g, '-');
  
  return {
    storeInfo: {
      name: storeName,
      hours: '',
      hasMultipleLocations: false,
      contactInfo: '',
      website: ''
    },
    branding: DEFAULT_BRANDING,
    assistant: DEFAULT_ASSISTANT,
    leads: {
      adminMobile: '',
      adminEmail: '',
      redirectUrl: ''
    },
    features: DEFAULT_FEATURES,
    chatConfig: DEFAULT_CHAT_CONFIG,
    subdomain,
    chatUrl: `${subdomain}.chat.mattressai.co`,
    version: 1,
    createdAt: new Date(),
    updatedAt: new Date()
  };
} 
import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Store, Palette, MessageSquare, Phone, CheckCircle } from 'lucide-react';
import { useOnboardingStore } from '../../stores/onboardingStore';
import Button from '../ui/Button';
import StoreBasicsStep from './steps/StoreBasicsStep';
import BrandingStep from './steps/BrandingStep';
import AssistantStep from './steps/AssistantStep';
import LeadCaptureStep from './steps/LeadCaptureStep';
import FinalStep from './steps/FinalStep';
import { useAuth } from '../../hooks/useAuth';
import { ErrorBoundary } from '../common/ErrorBoundary';
import toast from 'react-hot-toast';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { MerchantCustomization } from '../../services/merchant/config';

async function loadSettings(userId: string): Promise<MerchantCustomization> {
  const configRef = doc(db, `merchants/${userId}/merchantConfig/settings`);
  const configDoc = await getDoc(configRef);
  if (!configDoc.exists()) {
    throw new Error('Settings not found');
  }
  return configDoc.data() as MerchantCustomization;
}

async function saveSettings(userId: string, config: Partial<MerchantCustomization>) {
  const configRef = doc(db, `merchants/${userId}/merchantConfig/settings`);
  await setDoc(configRef, {
    ...config,
    updatedAt: serverTimestamp()
  }, { merge: true });
}

const steps = [
  {
    title: 'Store Basics',
    description: 'Create your store',
    icon: Store,
    component: StoreBasicsStep,
    key: 'storeBasics' as const,
    required: true
  },
  {
    title: 'Brand Identity',
    description: 'Customize your chat appearance',
    icon: Palette,
    component: BrandingStep,
    key: 'brandIdentity' as const,
    required: false
  },
  {
    title: 'Assistant Personality',
    description: 'Configure your AI assistant',
    icon: MessageSquare,
    component: AssistantStep,
    key: 'assistantPersonality' as const,
    required: false
  },
  {
    title: 'Lead Capture',
    description: 'Set up lead notifications',
    icon: Phone,
    component: LeadCaptureStep,
    key: 'leadCapture' as const,
    required: false
  },
  {
    title: 'Ready to Go!',
    description: 'Launch your AI assistant',
    icon: CheckCircle,
    component: FinalStep,
    key: 'chatSetup' as const,
    required: true
  },
];

export default function OnboardingPopup() {
  const { user } = useAuth();
  const { 
    currentStep, 
    stepsCompleted, 
    isComplete, 
    config, 
    updateLiveChat,
    deploy,
    completeOnboarding,
    setConfig 
  } = useOnboardingStore();

  // Load initial configuration
  useEffect(() => {
    const loadConfig = async () => {
      if (!user) return;
      
      try {
        const loadedConfig = await loadSettings(user.uid);
        setConfig(loadedConfig);
      } catch (error) {
        console.error('Error loading initial config:', error);
        toast.error('Failed to load configuration');
      }
    };

    loadConfig();
  }, [user, setConfig]);

  // Save configuration changes
  useEffect(() => {
    const saveConfig = async () => {
      if (!user || !config?.storeInfo.name) return;
      
      try {
        await saveSettings(user.uid, config);
        await updateLiveChat();
      } catch (error) {
        console.error('Failed to update configuration:', error);
        if (error instanceof Error && !error.message.includes('No user found')) {
          toast.error('Failed to save changes. Please try again.');
        }
      }
    };

    saveConfig();
  }, [user, config, updateLiveChat]);

  // Prevent scrolling when popup is open
  useEffect(() => {
    if (!isComplete) {
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = 'unset';
      };
    }
  }, [isComplete]);

  if (isComplete) return null;

  const CurrentStepComponent = steps[currentStep].component;

  const handleSaveConfiguration = async () => {
    try {
      if (!user || !config) {
        toast.error('You must be logged in to complete onboarding');
        return;
      }

      // Save final configuration
      await saveSettings(user.uid, config);
      
      // Deploy and complete onboarding
      await deploy();
      completeOnboarding();
      
      toast.success('Assistant launched successfully!');
    } catch (error) {
      console.error('Failed to complete onboarding:', error);
      toast.error(error instanceof Error ? error.message : 'Failed to launch assistant');
    }
  };

  return (
    <AnimatePresence>
      <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50">
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          className="fixed inset-4 md:inset-10 bg-white rounded-2xl shadow-2xl overflow-hidden flex flex-col"
        >
          {/* Header */}
          <div className="flex-shrink-0 border-b p-6">
            <div className="flex items-center justify-between mb-2">
              <h2 className="text-2xl font-bold">Welcome to MattressAI</h2>
              <div className="text-sm text-gray-500">
                Step {currentStep + 1} of {steps.length}
              </div>
            </div>
            <div className="flex gap-2">
              {steps.map((step, index) => (
                <div
                  key={step.key}
                  className="flex-1 h-1 rounded-full overflow-hidden bg-gray-200"
                >
                  <motion.div
                    initial={false}
                    animate={{
                      width: stepsCompleted[step.key] ? '100%' : 
                             currentStep === index ? '50%' : '0%'
                    }}
                    className="h-full bg-blue-500"
                  />
                </div>
              ))}
            </div>
          </div>

          {/* Content */}
          <div className="flex-1 overflow-y-auto p-6">
            <div className="max-w-2xl mx-auto">
              <div className="flex items-center gap-4 mb-8">
                {React.createElement(steps[currentStep].icon, {
                  className: "w-8 h-8 text-blue-500",
                })}
                <div>
                  <h3 className="text-xl font-semibold">{steps[currentStep].title}</h3>
                  <p className="text-gray-500">{steps[currentStep].description}</p>
                </div>
              </div>

              <ErrorBoundary>
                <CurrentStepComponent />
              </ErrorBoundary>
            </div>
          </div>

          {/* Footer */}
          <div className="flex-shrink-0 border-t p-6">
            <div className="max-w-2xl mx-auto flex justify-between">
              <Button
                variant="secondary"
                onClick={() => useOnboardingStore.getState().setStep(currentStep - 1)}
                disabled={currentStep === 0}
              >
                Back
              </Button>
              {currentStep < steps.length - 1 ? (
                <div className="flex gap-2">
                  {!steps[currentStep].required && (
                    <Button
                      variant="secondary"
                      onClick={() => {
                        useOnboardingStore.getState().completeStep(steps[currentStep].key);
                        useOnboardingStore.getState().setStep(currentStep + 1);
                      }}
                    >
                      Skip
                    </Button>
                  )}
                  <Button
                    variant="primary"
                    onClick={() => {
                      useOnboardingStore.getState().completeStep(steps[currentStep].key);
                      useOnboardingStore.getState().setStep(currentStep + 1);
                    }}
                  >
                    Continue
                  </Button>
                </div>
              ) : (
                <Button
                  variant="primary"
                  onClick={handleSaveConfiguration}
                >
                  Launch Assistant
                </Button>
              )}
            </div>
          </div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
} 